.footer-section {
  background-color: #24282c;
}

.logo {
  height: 55px;
  width: 55px;
  border-radius: 40px;
}
.footer-section-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  padding-top: 15px;
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 15px;
  background-color: white;
  margin-right: 8px;
  margin-bottom: 8px;
}
.icon {
  color: #c8c9dd;
  font-size: 18px;
}
.footer-section-text {
  color: #c8c9dd;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}
.footer-section-heading {
  color: white;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 700;
}
.footer-section-list {
  list-style-type: none;
  padding-left: 0;
}

.footer-copyright-section {
  background-color: #c8c9dd !important;
}
.footer-section-copyright {
  color: white;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
}

.footer-logo {
  height: 35px;
  width: 35px;
  border-radius: 40px;
  margin-right: 10px;
  color: white;
}

.facebook:hover {
  color: #1a74e4;
  cursor: pointer;
}

.insta:hover {
  color: #8a3ab9;
  cursor: pointer;
}

.linkedin:hover {
  color: #0072b1;
  cursor: pointer;
}

.github:hover {
  color: #171515;
  cursor: pointer;
}

.hr-footer {
  color: white;
}

.footer-item-link {
  color: #c8c9dd;
  text-decoration: none;
  background-color: transparent;
}

.footer-item-link:hover {
  color: #157c68;
  cursor: pointer;
}

@media (max-width: 768px) {
  .alok-logo {
    height: 35px;
    width: 35px;
    border-radius: 40px;
  }

  .margin-padding {
    padding-top: 20px;
  }
}
