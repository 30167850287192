@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Montserrat", sans-serif;
}

body,
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

.font-bold {
  font-weight: bold;
}

.flex-with-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.n-box1 {
  background: linear-gradient(145deg, #00101d, #001322);
  box-shadow: 5px 5px 0px #00070d, -5px -5px 0px #001d33;
}

.n-box2 {
  background: #ebecf0;
  box-shadow: 5px 5px 0px #cacbce, -5px -5px 0px #ffffff;
}

anc-link {
  text-decoration: none !important;
}

anc-link hover {
  color: #157c68 !important;
  cursor: pointer !important;
}
