.nav-container {
  background: transparent;
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.nav-container.colorChange {
  background: #24282c;
}
.udaan-logo {
  height: 35px;
  width: 35px;
  border-radius: 40px;
}

.nav-heading {
  color: white;
  font-weight: 700px !important;
}

.nav-link {
  color: white !important;
  font: 500px;
}

.nav-link.active {
  color: #157c68;
}

.resource-icon {
  height: 20px;
  width: 20px;
  border-radius: 40px;
  color: white;
}
.margin-padding {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .udaan-logo {
    height: 55px;
    width: 55px;
    border-radius: 40px;
  }

  .margin-padding {
    padding-top: 20px;
  }
}
