.explore-project-section {
  background-color: #fafafa;
  padding: 40px;
}
.project-section-heading {
  color: #157c68;
  font-family: "Roboto";
  font-size: 28px;
  font-weight: 700;
}
