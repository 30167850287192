.banner-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.upper-banner-container {
  height: 100vh;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.banner-heading {
  color: white;
  font-family: "opensans-bold, sans-serif";
  font-size: 80px;
  font-weight: 400;
}

.banner-caption {
  color: #f5f7fa;
  font-family: "Roboto";
  font-size: 30px;
  font-weight: 300;
}

.banner-card {
  height: 35vh;
  background-color: white;
  width: 100%;
  max-width: 1024px;
  margin-top: -20vh;
  z-index: 2;
}

.img-banner-card {
  max-width: 100%;
  height: 90%;
}

.banner-section-image {
  max-width: 100%;
  height: 100%;
}

.banner-section-heading {
  color: #157c68;
  font-family: "Roboto";
  font-size: 45px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}
.banner-section-description {
  color: #157c68;
  font-family: "Roboto";
  font-size: 24px;
  text-align: center;
}

.banner-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.hrstyle {
  color: #157c68 !important;
}

.social-logo {
  height: 35px;
  width: 35px;
  border-radius: 40px;
  margin-right: 10px;
  color: #157c68;
}

.facebook:hover {
  color: #1a74e4;
  cursor: pointer;
}

.insta:hover {
  color: #8a3ab9;
  cursor: pointer;
}

.linkedin:hover {
  color: #0072b1;
  cursor: pointer;
}

.github:hover {
  color: #171515;
  cursor: pointer;
}

.resume-download-icon {
  height: 15px;
  width: 15px;
  margin-left: 5px;
}

@media (max-width: 768px) {
  .banner-heading {
    font-size: 35px;
    font-weight: 400px;
  }

  .banner-caption {
    font-size: 20px;
    font-weight: 300;
  }

  .banner-section-heading {
    font-size: 35px;
    margin-top: 10px;
  }
  .banner-section-description {
    font-size: 20px;
    text-align: center;
  }

  .social-logo {
    height: 35px;
    width: 35px;
    border-radius: 40px;
  }

  .margin-padding {
    padding-top: 20px;
  }
}
